<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import { required } from "vuelidate/lib/validators";

export default {
  locales: {
    en: {
    },
    pt: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: {
    Layout,
    VclList
  },
  data() {
    return {
      table: {
        heade: [
          "#",
          "Date",
          "Operation",
          "Broker",
          "Type",
          "Server",
          "Login",
          "Status",
        ],
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },

      total: 0,

      create: {
        modal: false,
        loading: false,

        operation: '',
        broker: '',
        type: '',
        server: '',
        login: '',
        password: '',
      },

      sent: {
        modal: false,
        loading: false,

        id: '',
      },

      del: {
        modal: false,
        loading: false,

        id: '',
      }
    };
  },
  validations: {
    create: {
      operation: { required },
      broker: { required },
      type: { required },
      server: { required },
      login: { required },
      password: { required },
    }
  },
  methods: {
    getList() {
      this.table.body = null;

      this.table.loading = true;
      this.table.errored = false;
      this.table.empty = false;

      api
        .get("metatrader")
        .then((response) => {
          if (response.data.status == "success") {
            this.table.body = response.data.list;
            this.total = response.data.list.length;
          }
        })
        .catch((error) => {
          this.table.errored = error;
        })
        .finally(() => {
          this.table.loading = false;
          if (this.table.body == "" || this.table.body == null) {
            this.table.empty = true;
          }
        });
    },
    createAccount() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.create.loading = true;

        api
          .post("metatrader", {
            operation: this.create.operation,
            broker: this.create.broker,
            type: this.create.type,
            server: this.create.server,
            login: this.create.login,
            password: this.create.password,
          })
          .then((res) => {
            if (res.data.status == "success") {
              this.create.operation = "";
              this.create.broker = "";
              this.create.type = "";
              this.create.server = "";
              this.create.login = "";
              this.create.password = "";
              this.$v.$reset();

              this.getList();
            } else {
              this.$toast.error(res.data.message);
            }

            this.create.modal = false;
          })
          .finally(() => {
            this.create.loading = false;
          });
      }
    },
    showSent(id) {
      this.sent.modal = false;
      this.sent.modal = true;
      this.sent.id = id;
    },
    sentAccount() {
      if (this.sent.id) {
        this.sent.modal = false;

        api.put("metatrader/" + this.sent.id).then((res) => {
          if (res.data.status == "success") {
            this.getList();
            this.$noty.success('The account was sent successfully.');
          }
        })
      }
    },
    showDel(id) {
      this.del.modal = false;
      this.del.modal = true;
      this.del.id = id;
    },
    delAccount() {
      if (this.del.id) {
        this.del.modal = false;

        api.delete("metatrader/" + this.del.id).then((res) => {
          if (res.data.status == "success") {
            this.getList();
            this.$noty.success('The account was deleted successfully.');
          }
        })
      }
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">MetaTrader</h4>
          <div class="page-title-right">
          </div>
        </div>
      </div>
    </div>

    <div id="dropdown" class="d-flex mb-4">
      <div class="totals">
        <h6 class="m-0">{{ this.total }}</h6>
        <h6 class="m-0 text-secondary">Total de contas</h6>
      </div>
      <b-dropdown class="bg-white" variant="outline-light">
        <template #button-content>
          Actions
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="m12 14.168-5.582-5.61L5 9.967 12 17l7-7-1.415-1.414L12 14.168Z" fill="currentColor"></path>
          </svg>
        </template>
        <b-dropdown-item v-on:click="create.modal = false; create.modal = true">Create</b-dropdown-item>
      </b-dropdown>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">
              {{
              t(
              "We apologize, we are unable to retrieve information at this time. Please try again later."
              )
              }}
            </div>
            <div v-else-if="table.empty">{{ t("No records found.") }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td, index) in table.body" :key="index">
                    <td v-for="(data, row) in td" :key="row" class="notranslate">
                      <div v-if="row === 'id'">
                        {{ data.split('-')[0] }}
                      </div>
                      <div v-else-if="row === 'value'">
                        {{ data | currency }}
                      </div>
                      <div v-else-if="row === 'bonus'">
                        {{ data | currency }}
                      </div>
                      <div v-else-if="row === 'status'">
                        <span v-if="data == 'pending'" class="bg-danger px-1 rounded text-white text-uppercase">Pending</span>
                        <span v-else-if="data == 'sent'" class="bg-warning px-1 rounded text-white text-uppercase">Awaiting activation</span>
                        <span v-else-if="data == 'active'" class="bg-success px-1 rounded text-white text-uppercase">Active</span>
                        <span v-else-if="data == 'inactive'" class="bg-dark px-1 rounded text-white text-uppercase">Inactive</span>
                      </div>
                      <div v-else>
                        {{ data }}
                      </div>
                    </td>
                    <td class="text-right">
                      <template v-if="td.status == 'pending'">
                        <button class="btn btn-success mr-2" v-on:click="showSent(td.id)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" style="fill: rgba(255, 255, 255, 1);">
                            <path
                              d="m21.426 11.095-17-8A.999.999 0 0 0 3.03 4.242L4.969 12 3.03 19.758a.998.998 0 0 0 1.396 1.147l17-8a1 1 0 0 0 0-1.81zM5.481 18.197l.839-3.357L12 12 6.32 9.16l-.839-3.357L18.651 12l-13.17 6.197z">
                            </path>
                          </svg>
                        </button>
                        <button class="btn btn-danger" v-on:click="showDel(td.id)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" style="fill: rgba(255, 255, 255, 1);">
                            <path d="M15 2H9c-1.103 0-2 .897-2 2v2H3v2h2v12c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2V8h2V6h-4V4c0-1.103-.897-2-2-2zM9 4h6v2H9V4zm8 16H7V8h10v12z"></path>
                          </svg>
                        </button>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="create.modal" modal-class="modal-right" hide-header hide-footer>
      <div>
        <h4 class="mb-5">Create a new account</h4>
        <b-form @submit.prevent="createAccount">
          <b-form-group id="operation" label="Operation" label-for="operation" class="required">
            <select v-model="create.operation" class="custom-select mb-1">
              <option value=""></option>
              <option value="one">One</option>
              <option value="trust" class="d-none">Trust</option>
              <option value="gold">Gold</option>
            </select>
            <div v-if="$v.create.operation.$error" class="invalid-feedback">
              <span v-if="!$v.create.operation.required">The operation is required.</span>
            </div>
          </b-form-group>
          <b-form-group id="broker" label="Broker" label-for="broker" class="required">
            <b-form-input id="broker" v-model="create.broker" type="text" :class="{ 'is-invalid': $v.create.broker.$error }"></b-form-input>
            <div v-if="$v.create.broker.$error" class="invalid-feedback">
              <span v-if="!$v.create.broker.required">The broker is required.</span>
            </div>
          </b-form-group>
          <b-form-group id="type" label="Type" label-for="type" class="required">
            <select v-model="create.type" class="custom-select mb-1">
              <option value=""></option>
              <option value="MT4">MT4</option>
              <option value="MT5">MT5</option>
            </select>
            <div v-if="$v.create.type.$error" class="invalid-feedback">
              <span v-if="!$v.create.type.required">The type is required.</span>
            </div>
          </b-form-group>
          <b-form-group id="server" label="Server" label-for="server" class="required">
            <b-form-input id="server" v-model="create.server" type="text" :class="{ 'is-invalid': $v.create.server.$error }"></b-form-input>
            <div v-if="$v.create.server.$error" class="invalid-feedback">
              <span v-if="!$v.create.server.required">The server is required.</span>
            </div>
          </b-form-group>
          <b-form-group id="login" label="Login" label-for="login" class="required">
            <b-form-input id="login" v-model="create.login" type="text" :class="{ 'is-invalid': $v.create.login.$error }"></b-form-input>
            <div v-if="$v.create.login.$error" class="invalid-feedback">
              <span v-if="!$v.create.login.required">The login is required.</span>
            </div>
          </b-form-group>
          <b-form-group id="password" label="Password" label-for="password" class="required">
            <b-form-input id="password" v-model="create.password" type="text" :class="{ 'is-invalid': $v.create.password.$error }"></b-form-input>
            <div v-if="$v.create.password.$error" class="invalid-feedback">
              <span v-if="!$v.create.password.required">The password is required.</span>
            </div>
          </b-form-group>
          <div class="mt-4 d-flex justify-content-between">
            <b-button :disabled="this.create.loading == true || this.$v.$invalid" type="submit" variant="default">
              Confirm
              <b-spinner v-if="create.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
            </b-button>
            <a class="btn btn-outline-link" v-on:click="create.modal = false;">Close <sup>ESC</sup></a>
          </div>
        </b-form>
      </div>
    </b-modal>

    <b-modal v-model="sent.modal" modal-class="modal-bottom" hide-header hide-footer>
      <div>
        <h4>Active account</h4>
        <p>Confirm the submission of the account for activation?</p>
        <div class="d-flex justify-content-between">
          <button class="btn btn-default mr-3" v-on:click="sentAccount()">Confirm</button>
          <button class="btn btn-outline-link" v-on:click="sent.modal = false">Close <sup>ESC</sup></button>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="del.modal" modal-class="modal-bottom" hide-header hide-footer>
      <div>
        <h4>Delete account</h4>
        <p>Confirm the exclusion of the account?</p>
        <div class="d-flex justify-content-between">
          <button class="btn btn-default mr-3" v-on:click="delAccount()">Confirm</button>
          <button class="btn btn-outline-link" v-on:click="del.modal = false">Close <sup>ESC</sup></button>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>

<style scoped>
tbody tr td {
  height: 55px !important;
  vertical-align: middle;
}
tbody tr td .btn {
  padding: 5px 8px;
}
</style>